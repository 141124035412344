<template>
  <Layout :tituloPagina="`Facturas | Series | ${modo == 'nueva' ? 'Nueva' : 'Edición'}`">
    <div class="row">
      <div class="col-lg-4">
        <cmp-secciones seccion="series"></cmp-secciones>
      </div>
    </div>
    
    <div class="row">
      <div class="col-md-7">
        <div>
          <div class="card">
            <div class="card-header justify-content-between d-flex align-items-center">
              <h4 class="card-title">
                {{ modo == 'nueva' ? 'Nueva' : 'Edición de' }} serie de facturas
              </h4>
              <div>
                <div class="dropdown">
                  <a
                    class="dropdown-toggle"
                    href="javascript: void(0);"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <eva-icon
                      name="more-horizontal-outline"
                      data-eva-width="20"
                      data-eva-height="20"
                      :class="{
                        'fill-dark': $store.state.layout.layoutMode == 'light',
                        'fill-white': $store.state.layout.layoutMode == 'dark'
                      }"
                    ></eva-icon>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        href="#"
                        class="dropdown-item"
                        @click="modo == 'nueva' ? guardar() : actualizar()"
                        onclick="return false;"
                        v-show="!bandera_spinner"
                      >
                        <i class="mdi mdi-content-save text-success"></i>
                        {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
                      </a>
                      <span class="dropdown-item disabled" v-if="bandera_spinner">
                        <i class="mdi mdi-concourse-ci mdi-spin"></i>
                        {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
                      </span>
                    </li>
                    <li>
                      <a
                        class="dropdown-item" href="#"
                        onclick="return false;" @click="cancelar()"
                      >
                        <i class="mdi mdi-close text-dark"></i>
                        Cerrar
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <label class="col-sm-3">Prefijo</label>
                <div class="col-sm-3">
                  <input
                    ref="prefijo"
                    class="form-control"
                    placeholder="Prefijo"
                    v-model="serie.prefijo"
                    maxlength="5"
                  />
                </div>
                <label class="col-sm-3">Consecutivo</label>
                <div class="col-sm-3">
                  <input
                    ref="consecutivo"
                    class="form-control"
                    placeholder="#N°"
                    v-model="serie.consecutivo"
                    :readonly="modo == 'nueva'"
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-md-3">Nombre</label>
                <div class="col-md-6">
                  <input
                    ref="nombre"
                    class="form-control"
                    placeholder="Nombre de la serie"
                    v-model="serie.nombre"
                  />
                </div>
              </div>

              <div class="row">
                <label class="col-md-3">Descripción</label>
                <div class="col-md-9">
                  <textarea
                    ref="descripcion"
                    rows="4"
                    class="form-control"
                    placeholder="Descripción de la serie de factura"
                    v-model="serie.descripcion"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="text-right">
            <div class="btn-group">
              <button class="btn btn-secondary" @click="cancelar()">
                <i class="mdi mdi-chevron-left"></i>
                Atras
              </button>
  
              <button
                class="btn btn-success"
                @click="modo == 'nueva' ? guardar() : actualizar()"
                :disabled="bandera_spinner"
              >
                <i
                  class="mdi"
                  :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
                ></i>
                {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import CmpSecciones from './CmpSecciones.vue'
import SerieFacturasSrv from '@/services/SerieFacturasSrv.js'

export default {
  name: 'Adm3ArgusblackSerieFacturas',
  components: { Layout, CmpSecciones },
  data() {
    return {
      serie: {
        nombre: '',
        prefijo: '',
        descripcion: '',
        consecutivo: 1,
        activo: true
      },
      serieInicial: null,
      // Detección del modo
      modo: this.$route.path.indexOf('series/nueva') >= 0 ? 'nueva' : 'edicion',
      bandera_spinner: false
    }
  },
  created: function() {
    // Copia inicial
    this.serieInicial = Object.assign({}, this.serie)

    // Carga de la serie
    if (this.modo == 'edicion') this.cargarSerie()
  },
  methods: {
    actualizar: function() {
      var self = this
      var idSerie = this.$route.params.id

      self.bandera_spinner = true

      // Verificación de datos
      if (this.serie.prefijo == '') {
        iu.msg.warning('Es necesario un prefijo')
        self.$refs.prefijo.select()
        self.bandera_spinner = false
        return
      }

      if (this.serie.nombre == '') {
        iu.msg.warning('Es necesario una nombre')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      SerieFacturasSrv.actualizar(self.serie).then(response => {
        iu.msg.success('Se actualizó correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }

        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },
    cancelar: function() {
      this.$router.go(-1)
    },
    cargarSerie: function() {
      var self = this
      var idSerie = this.$route.params.id

      SerieFacturasSrv.serieJSON(idSerie).then(response => {
        self.serie = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la serie'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    guardar: function() {
      var self = this
      var serie = Object.assign({}, this.serie)

      self.bandera_spinner = true

      // Verificación de datos
      if (this.serie.prefijo == '') {
        iu.msg.warning('Es necesario un prefijo')
        self.$refs.prefijo.select()
        self.bandera_spinner = false
        return
      }

      if (this.serie.nombre == '') {
        iu.msg.warning('Es necesario una nombre')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      SerieFacturasSrv.guardar(serie).then(response => {
        iu.msg.success('Se guardó correctamente')
        self.limpiar()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar la serie'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },
    limpiar: function() {
      this.serie = Object.assign(this.serie, this.serieInicial)
    }
  }
}
</script>